import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

// import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/como-funciona/como-funciona.page.component";
// import { RegisterpageComponent } from "./pages/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/index/index.page.component";

const routes: Routes = [
  { path: "", redirectTo: "autosmart", pathMatch: "full" },
  { path: "autosmart", component: LandingpageComponent },
  //{ path: 'autosmart', loadChildren: () => import('./pages/landingpage/landingpage.component').then(m => m.LandingpageComponent) },  // { path: "home", component: IndexComponent },
  { path: "como-funciona", component: ProfilepageComponent },
  // { path: "register", component: RegisterpageComponent },
  // { path: "landing", component: LandingpageComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    })
  ],
  exports: []
})
export class AppRoutingModule {}
